import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/images/ptm_logo.png";
import { Image } from "react-bootstrap";

const Footer = () => {
  const style = {
    container: {
      backgroundColor: "#25282a",
      minHeight: "20vh"
    },
    textContent: {
      color: "#D3D3D3",
      fontWeight: "100",
      paddingBottom: "0px"
    },
    col: {
      height: "100%",
      padding: "20px"
    },
    colPadding: {
      paddingLeft: "3em",
      paddingRight: "3em"
    },
    iconPaddingLeft: {
      marginRight: "1em",
      color: "white"
    },
    iconPaddingRight: {
      marginLeft: "1em"
    },
    footerLogo: {
      height: "4em",
      marginTop: "2em",
      marginBottom: "2em"
    },
    ad: {
      color: "white",
      textDecoration: "none"
    },
    iconColorRed: {
      color: "red"
    }
  };
  return (
    <Container fluid style={style.container}>
      <Row style={style.col}>
        <Col
          xs={12}
          md={4}
          className="d-flex flex-grow-1 flex-column align-items-center justify-content-center"
          style={style.colPadding}
        >
          <a href="tel:0760820178">
            <p style={style.textContent}>
              <FontAwesomeIcon icon={faPhone} style={style.iconPaddingLeft} />
              076 0820178
            </p>
          </a>
          <a href="mailto:info@ptmarcus.com">
            <p style={style.textContent}>
              <FontAwesomeIcon
                icon={faEnvelope}
                style={style.iconPaddingLeft}
              />
              info@ptmarcus.com
            </p>
          </a>
          <a
            href="https://www.instagram.com/pt_marcus.englund/"
            style={style.textContent}
          >
            <FontAwesomeIcon icon={faInstagram} style={style.iconPaddingLeft} />
            Följ pt-marcus på Instragram
          </a>
        </Col>
        <Col
          xs={12}
          md={4}
          className="d-flex flex-grow-1 flex-column align-items-center justify-content-center"
        >
          <Image
            src={logo}
            alt="Träningsexperten Stockholm AB"
            style={style.footerLogo}
          />
          <p style={style.ad}>
            Made with{" "}
            <FontAwesomeIcon icon={faHeart} style={style.iconColorRed} /> by{" "}
            <a
              href="https://www.simonwikenstahl.se"
              target="_blank"
              style={style.ad}
            >
              Simon Wikenståhl
            </a>
          </p>
        </Col>
        <Col
          xs={12}
          md={4}
          className="d-flex flex-grow-1 flex-column align-items-center justify-content-center"
          style={style.colPadding}
        >
          <p style={style.textContent}>Homie Fitness östermalm</p>
          <p style={style.textContent}>Skeppargatan 28</p>
          <p style={style.textContent}>114 52 Stockholm</p>
          <p style={style.textContent}>Tunnelbanestation: Östermalmstorg</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
