import React from "react";
import Col from "react-bootstrap/Col";
import { withRouter } from "react-router-dom";

class Offerings extends React.Component {
  state = {
    toKost: false,
    toForetag: false,
    toPersonlig: false
  };

  navigate = linkTo => {
    this.props.history.push(linkTo);
  };

  render() {
    const { image, heading, linkTo } = this.props;

    const style = {
      img: {
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        height: "20vh",
        backgroundPosition: "center",
        paddingLeft: "0px",
        paddingRight: "0px",
        cursor: "pointer",
        border: "1px",
        borderStyle: "solid",
        borderColor: "white"
      },
      centerHeading: {
        height: "100%"
      },
      headingText: {
        color: "white",
        fontSize: "2rem"
      },
      subText: {
        color: "white"
      },
      overlay: {
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        paddingLeft: "-15px",
        paddingRight: "-15px"
      }
    };

    return (
      <>
        <Col
          xs={12}
          lg={4}
          className="d-flex justify-content-center"
          style={style.img}
          onClick={() => {
            this.navigate(linkTo);
          }}
        >
          <div style={style.overlay}>
            <div
              className="d-flex flex-column flex-grow-1 align-items-center justify-content-center"
              style={style.centerHeading}
            >
              <h4 style={style.headingText}>{heading}</h4>
            </div>
          </div>
        </Col>
      </>
    );
  }
}
export default withRouter(Offerings);
