import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class TopArrow extends Component {
  state = {
    showUpBtn: null
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    "use strict";
    let myNav = document.getElementById("navbar");

    /* window.onscroll = function() { */
    const ScrolledDown =
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50 ||
      window.scrollY >= 50 ||
      window.pageYOffset >= 50;

    if (ScrolledDown) {
      try {
        if (!this.state.showUpBtn) {
          this.setState({ showUpBtn: true });
        }

        myNav.classList.add("navbar-scroll");
      } catch (err) {}
    } else {
      try {
        if (this.state.showUpBtn) {
          this.setState({ showUpBtn: false });
        }
        myNav.classList.remove("navbar-scroll");
      } catch (err) {}
    }
    /* }; */
  };
  render() {
    let upBtn = <input type="hidden" />;

    if (this.state.showUpBtn) {
      upBtn = (
        <Button
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            backgroundColor: "transparent",
            borderColor: "transparent"
          }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <FontAwesomeIcon
            icon={faArrowCircleUp}
            style={{ color: "rgba(171,170,170,0.5)", fontSize: 30 }}
          />
        </Button>
      );
    }
    return <>{upBtn}</>;
  }
}
