import React from "react";
import bgTraningstips from "../../assets/images/bg_pt-marcus_kontakt.png";
import HeaderImage from "../General/HeadImage";
import Footer from "../Footer/Footer";
import NavRoute from "../NavRoute/NavRoute";
import Map from "../General/Map";
import MailForm from "./MailForm";
import { Container, Row, Col } from "react-bootstrap";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import ptMarcus from "../../assets/images/bg_pt-marcus_bg2.png";

const style = {
  contentContainer: {
    borderTop: "1px",
    borderTopStyle: "solid",
    borderTopColor: "black",
    paddingTop: "2em",
    borderRadius: "10px",
    borderBottom: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "black",
    paddingBottom: "2em",
    marginBottom: "2em"
  },
  mailContainer: {
    marginTop: "4em",
    marginBottom: "4em"
  },
  iconPaddingLeft: {
    marginRight: "1em",
    color: "black"
  },
  textContent: {
    color: "black",
    fontWeight: "100",
    paddingBottom: "0px"
  },
  location: {
    paddingTop: "3em"
  }
};

const Traningstips = () => {
  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PT Marcus, personlig tränare på östermalm Kontakta mig!</title>
        <meta
          name="description"
          content="Marcus Englund, en personlig tränare i hjärtat på östermalm. Kontakta mig idag!"
        />
        <meta
          property="og:title"
          content="PT Marcus din personliga tränare på östermalm, stockholm."
        />
        <meta
          property="og:description"
          content="PT Marcus erbjuder personlig, duo, samt företags träning, i en trygg miljö i centrala östermalm."
        />
        <meta property="og:url" content="https://www.ptmarcus.com" />
        <meta property="og:site_name" content="pt-marcus" />
        <meta property="og:type" content="Website" />
        <meta
          name="keywords"
          content="personlig, pt, stockholm, personlig tränare, östermalm"
        />
        <meta property="og:image" content={ptMarcus} />
      </Helmet>
      <NavRoute />
      <HeaderImage
        bgImage={bgTraningstips}
        heading={"Kontakt"}
        subHeading={"Jag träffar er gärna för en gratis konsultation"}
      />
      <Container style={style.mailContainer}>
        <Row>
          <Col xs={12} lg={5} style={style.contentContainer}>
            <h4>Skicka ett mail</h4>
            <small>Så återkommer jag snarast möjligt</small>

            <MailForm />
          </Col>
          <Col lg={2} />

          <Col xs={12} lg={5} style={style.contentContainer}>
            <h4>Kontaktuppgifter</h4>
            <small>Övriga kontaktuppgifter</small>

            <div
              className="d-flex flex-grow-1 justify-content-center align-items-center flex-column"
              style={{ marginTop: "2em", height: "70%" }}
            >
              <a href="tel:0760820178">
                <p style={style.textContent}>
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={style.iconPaddingLeft}
                  />
                  076 0820178
                </p>
              </a>
              <a href="mailto:info@ptmarcus.com">
                <p style={style.textContent}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={style.iconPaddingLeft}
                  />
                  info@ptmarcus.com
                </p>
              </a>
              <a
                href="https://www.instagram.com/pt_marcus.englund/"
                style={style.textContent}
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={style.iconPaddingLeft}
                />
                Följ pt-marcus på Instragram
              </a>
              <div style={style.location}>
                <h5>Besöksadress</h5>
                <p style={style.textContent}>Homie Fitness östermalm</p>
                <p style={style.textContent}>Skeppargatan 28</p>
                <p style={style.textContent}>114 52 Stockholm</p>
                <p style={style.textContent}>
                  Tunnelbanestation: Östermalmstorg
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Map />
      <Footer />
    </div>
  );
};

export default Traningstips;
