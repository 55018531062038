import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";

const Content = () => {
  const style = {
    contentContainer: {
      borderTop: "1px",
      borderTopStyle: "solid",
      borderTopColor: "black",
      paddingTop: "2em",
      borderRadius: "10px",
      borderBottom: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "black",
      paddingBottom: "2em",
      marginBottom: "2em"
    },
    welcomeText: {
      color: "black",
      lineHeight: "1.8em"
    },
    containerPadding: {
      paddingTop: "2em"
    }
  };
  return (
    <Container style={style.containerPadding}>
      <Row className="d-flex flex-grow-1 flex-row align-items-center justify-content-center">
        <Col
          xs={12}
          md={8}
          className="d-flex flex-grow-1 flex-column align-items-center justify-content-center"
          style={style.contentContainer}
        >
          <h2>Homie Fitness</h2>
          <p style={style.welcomeText}>
            På skeppargatan 28, i hjärtat på östermalm, ligger Homie Fitness.
            Ett topprenoverat, personligt gym med ambitionen att skapa en trygg
            samt personlig miljö tillsammans med dess medlemmar. Homie Fitness
            blir utgångspunkten för vår träning, med detta sagt vill jag även
            meddela att det är du, och din träning, som bestämmer vart vi utför
            träningspassen. Se lite inspirationsbilder nedan.
          </p>
          <Button
            variant="secondary"
            href="https://www.homie.fitness/"
            target="_blank"
          >
            Till Homie Fitness
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Content;
