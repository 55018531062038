import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Welcome = ({
  heading,
  text,
  showContactBtn,
  subOne,
  subTwo,
  textOne,
  textTwo,
  bulletOne,
  bulletTwo,
  bulletThree
}) => {
  const style = {
    contentContainer: {
      borderTop: "1px",
      borderTopStyle: "solid",
      borderTopColor: "black",
      paddingTop: "2em",
      borderRadius: "10px",
      borderBottom: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "black",
      paddingBottom: "2em",
      marginBottom: "2em"
    },
    welcomeText: {
      color: "black",
      lineHeight: "1.8em"
    },
    containerPadding: {
      paddingTop: "2em"
    }
  };

  let contactBtn = null;
  let content = (
    <Row className="d-flex flex-grow-1 flex-row align-items-center justify-content-center">
      <Col
        xs={12}
        md={8}
        className="d-flex flex-grow-1 flex-column align-items-center justify-content-center"
        style={style.contentContainer}
      >
        <h2>{heading}</h2>
        <p style={style.welcomeText}>{text}</p>
        {contactBtn}
      </Col>
    </Row>
  );
  if (subOne) {
    content = (
      <Row className="d-flex flex-grow-1 flex-row align-items-center justify-content-center">
        <Col
          xs={12}
          md={8}
          className="d-flex flex-grow-1 flex-column align-items-center justify-content-center"
          style={style.contentContainer}
        >
          <h3>{heading}</h3>
          <p style={style.welcomeText}>{text}</p>
          <h3>{subOne}</h3>
          <p style={style.welcomeText}>{textOne}</p>
          <h3>{subTwo}</h3>
          <p style={style.welcomeText}>{textTwo}</p>
          {contactBtn}
        </Col>
      </Row>
    );
  }

  if (bulletOne) {
    content = (
      <Row className="d-flex flex-grow-1 flex-row align-items-center justify-content-center">
        <Col
          xs={12}
          md={8}
          className="d-flex flex-grow-1 flex-column align-items-center justify-content-center"
          style={style.contentContainer}
        >
          <h3>{heading}</h3>
          <p style={style.welcomeText}>{text}</p>
          <ul style={{ textAlign: "left" }}>
            <li>{bulletOne}</li>
            <li>{bulletTwo}</li>
            <li>{bulletThree}</li>
          </ul>
          {contactBtn}
        </Col>
      </Row>
    );
  }

  if (showContactBtn) {
    contactBtn = (
      <Button variant="secondary" target="_blank">
        <Link style={{ textDecoration: "none", color: "white" }} to="/Kontakt">
          Kontakta PTMarcus
        </Link>
      </Button>
    );
  }
  return <Container style={style.containerPadding}>{content}</Container>;
};

export default Welcome;
