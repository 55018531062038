import React from "react";
import HeaderImage from "../General/HeadImage";
import Footer from "../Footer/Footer";
import NavRoute from "../NavRoute/NavRoute";
import { Helmet } from "react-helmet";
import ptMarcus from "../../assets/images/bg_pt-marcus_bg2.png";
import Content from "./Content";
import Gallery from "react-photo-gallery";
import gymbild_1 from "../../assets/images/gymet_1.png";
import gymbild_2 from "../../assets/images/gymet_2.png";
import gymbild_3 from "../../assets/images/gymet_3.png";
import gymbild_4 from "../../assets/images/gymet_4.png";
import gymbild_5 from "../../assets/images/gymet_5.png";
import gymbild_6 from "../../assets/images/gymet_6.png";
import { Container, Row, Col } from "react-bootstrap";

const photos = [
  {
    src: gymbild_1,
    width: 4,
    height: 3
  },
  {
    src: gymbild_2,
    width: 5,
    height: 4
  },
  {
    src: gymbild_3,
    width: 2,
    height: 3
  },
  {
    src: gymbild_4,
    width: 4,
    height: 3
  },
  {
    src: gymbild_5,
    width: 3,
    height: 3
  },
  {
    src: gymbild_6,
    width: 4,
    height: 3
  }
];

const style = {
  bottomRow: {
    marginBottom: "3em"
  }
};

const Gymet = () => {
  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PT Marcus, personlig tränare på östermalm</title>
        <meta
          name="description"
          content="Marcus Englund, en personlig tränare i hjärtat på östermalm. Se bilder på gymet här."
        />
        <meta
          property="og:title"
          content="PT Marcus din personliga tränare på östermalm, stockholm."
        />
        <meta
          property="og:description"
          content="PT Marcus erbjuder personlig, duo, samt företags träning, i en trygg miljö i centrala östermalm."
        />
        <meta property="og:url" content="https://www.ptmarcus.com" />
        <meta property="og:site_name" content="pt-marcus" />
        <meta property="og:type" content="Website" />
        <meta
          name="keywords"
          content="personlig, pt, stockholm, personlig tränare, östermalm"
        />
        <meta property="og:image" content={ptMarcus} />
      </Helmet>
      <NavRoute />
      <HeaderImage
        bgImage={ptMarcus}
        heading={"Gymet"}
        subHeading={"Nyrenoverade lokaler på östermalm"}
      />
      <Content />
      <Container>
        <Row style={style.bottomRow}>
          <Col />
          <Col lg={10}>
            <Gallery photos={photos} />
          </Col>
          <Col />
        </Row>
      </Container>

      <Footer />
    </div>
  );
};

export default Gymet;
