import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Image from "react-bootstrap/Image";
import profilbild from "../../assets/images/ptm_profil.png";

const AnyReactComponent = ({ text }) => (
  <Image
    src={profilbild}
    style={{
      height: "4em",
      width: "4em",
      position: "absolute",
      transform: "translate(-50%, -50%)"
    }}
    roundedCircle
  />
);

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 59.334501,
      lng: 18.083599
    },
    zoom: 15
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBVyZ7ZBcMxvDex-Qhxh069ksXSvX80j8I" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent lat={59.334501} lng={18.083599} text="My Marker" />
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
