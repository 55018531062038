import * as firebase from "firebase";
import React, { Component } from "react";
import flamelink from "flamelink";
import Post from "./Post";
import { Container, Row, Col } from "react-bootstrap";
import { SwapSpinner } from "react-spinners-kit";

export default class PostList extends Component {
  _isMounted = false;
  constructor() {
    super();
    this.limited = false;
  }

  state = {
    posts: [],
    loading: true
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.limited) {
      this.limited = true;
    }

    const firebaseConfig = {
      apiKey: "AIzaSyBhnuo4sYNzPU1qzzSfyQPmA_X0bzepFWk",
      authDomain: "pt-marcus.firebaseapp.com",
      databaseURL: "https://pt-marcus.firebaseio.com",
      projectId: "pt-marcus",
      storageBucket: "pt-marcus.appspot.com",
      messagingSenderId: "862799940249"
    };
    let firebaseApp = "";
    if (firebase.apps.length == false) {
      firebaseApp = firebase.initializeApp(firebaseConfig);
    } else {
      firebaseApp = firebase.app();
    }

    this.app = flamelink({ firebaseApp });

    let fetchedPosts = {
      postKey: "",
      rubrik: "",
      innehall: "",
      mainImage: "",
      skapadAv: ""
    }; /* [tipsData[currPost], tipsData[currPost].mainImage[0]]; */

    this.timeOutHandler = setTimeout(() => {
      this.app.content
        .get("traningstips")
        .then(tipsData => {
          console.log("Inne i content API Fetch");
          const postKeys = Object.keys(tipsData);
          let postArray = postKeys.map(currPost => {
            this.app.storage

              .getURL(tipsData[currPost].mainImage[0])
              .then(url => {
                console.log("Inne i storage API Fetch");
                let fetchedPosts = {
                  postKey: currPost,
                  rubrik: tipsData[currPost].rubrik,
                  innehall: tipsData[currPost].innehall,
                  mainImage: url,
                  skapadAv: tipsData[currPost].skapadAv
                };
                if (this._isMounted) {
                  this.setState(state => {
                    const posts = [...state.posts, fetchedPosts];

                    return {
                      posts,
                      loading: false
                    };
                  });
                }
              })
              .catch(error =>
                console.error(
                  "Something went wrong while retrieving the file URL. Details:",
                  error
                )
              );
          });
        })
        .catch(error =>
          console.error(
            "Something went wrong while retrieving all the content. Details:",
            error
          )
        );
    }, 50);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.app.content.unsubscribe("traningstips");
    this.app.storage.unsubscribe("*");
    if (this.timeOutHandler) {
      clearTimeout(this.timeOutHandler);
      this.timeOutHandler = 0;
    }
  }
  render() {
    const { loading } = this.state;
    const style = {
      container: {
        padding: "2em"
      }
    };

    let blogPosts = (
      <Container>
        <Row style={style.loadingContainer}>
          <Col className="d-flex flex-grow-1 justify-content-center align-items-center flex-column">
            <SwapSpinner size={50} color="#6c757d" loading={loading} />
            {/*  <img src={Logo} alt="PT-marcus logotype" style={style.logo} /> */}
          </Col>
        </Row>
      </Container>
    );
    if (!loading) {
      if (this.limited) {
        blogPosts = this.state.posts.slice(0, 3).map((currPost, i) => {
          return (
            <Col
              key={i}
              xs={12}
              md={4}
              lg={4}
              className="d-flex justify-content-center flex-grow-1 flex-row"
            >
              <Post
                image={currPost.mainImage}
                heading={currPost.rubrik}
                text={"Läs mer"}
                fullText={currPost.innehall}
                tipKey={currPost.postKey}
              />
            </Col>
          );
        });
      } else {
        blogPosts = this.state.posts.map((currPost, i) => {
          return (
            <Col
              key={i}
              xs={12}
              md={4}
              lg={4}
              className="d-flex justify-content-center flex-grow-1 flex-row"
            >
              <Post
                image={currPost.mainImage}
                heading={currPost.rubrik}
                text={"Läs mer"}
                fullText={currPost.innehall}
                tipKey={currPost.postKey}
              />
            </Col>
          );
        });
      }
    }

    return (
      <Container style={style.container}>
        <Row>{blogPosts}</Row>
      </Container>
    );
  }
}
