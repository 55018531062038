import React, { Component } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Home from "./Components/Home/Home";
import Gymet from "./Components/Gymet/Gymet";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Traningstips from "./Components/Traningstips/Traningstips";
import Kontakt from "./Components/Kontakt/Kontakt";
import Tips from "./Components/Tips/Tips";
import PersonligTraning from "./Components/PersonligTraning/PersonligTraning";
import KostRadgivning from "./Components/KostRadgivning/KostRadgivning";
import ForetagsTraning from "./Components/ForetagsTraning/ForetagsTraning";
import TopArrow from "./Components/General/TopArrow";

class App extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    "use strict";
    let myNav = document.getElementById("navbar");

    /* window.onscroll = function() { */
    const ScrolledDown =
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50 ||
      window.scrollY >= 50 ||
      window.pageYOffset >= 50;

    if (ScrolledDown) {
      try {
        myNav.classList.add("navbar-scroll");
      } catch (err) {}
    } else {
      try {
        myNav.classList.remove("navbar-scroll");
      } catch (err) {}
    }
    /* }; */
  };
  render() {
    return (
      <div className="App">
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={225} classNames="fade">
                <Switch location={location}>
                  <Route path="/" exact component={Home} />
                  <Route path="/Gymet" component={Gymet} />
                  <Route path="/Traningstips" component={Traningstips} />
                  <Route path="/Kontakt" component={Kontakt} />
                  <Route path="/Tips:myKey" component={Tips} />
                  <Route
                    path="/PersonligTraning"
                    component={PersonligTraning}
                  />
                  <Route path="/KostRadgivning" component={KostRadgivning} />
                  <Route path="/ForetagsTraning" component={ForetagsTraning} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
        {/* 08 - 120 99 200 / Kjelle på Electrolindning AB . 300-350 kvm, lastkaj/
        mindre lasthiss. Önsksvärt markplan. */}
        {/* <Footer /> */}
        <TopArrow />
      </div>
    );
  }
}

export default App;
