import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { SwapSpinner } from "react-spinners-kit";
import * as emailjs from "emailjs-com";

class MailForm extends Component {
  state = {
    from: "",
    phone: "",
    name: "",
    message: "",
    loading: false
  };

  handleChange = event => {
    let field = event.target.name;
    let value = event.target.value;
    this.setState({ [field]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ loading: true });

    const service_id = "ptmarcus123_gmail_com";
    const template_id = "template_0ar8HVe5";
    const user_id = "user_XfYpKghlHp2iEbzE1K6BF";
    const params = {
      from: this.state.from,
      phone: this.state.phone,
      name: this.state.name,
      message: this.state.message
    };

    /*    setTimeout(() => {
      this.setState({ loading: false });
      let elem = document.getElementById("sendBtn");

      elem.innerText = "Meddelandet har skickats";
      elem.setAttribute("class", "btn btn-success");
      elem.disabled = true;

      this.setState({
        from: "",
        phone: "",
        name: "",
        message: ""
      });
    }, 1000); */

    emailjs.send(service_id, template_id, params, user_id).then(
      () => {
        this.setState({ loading: false });
        let elem = document.getElementById("sendBtn");

        elem.innerText = "Meddelandet har skickats";
        elem.setAttribute("class", "btn btn-success");
        elem.disabled = true;

        this.setState({
          from: "",
          phone: "",
          name: "",
          message: ""
        });
      },
      err => {
        alert("Ett fel har uppstått: " + JSON.stringify(err));
      }
    );

    /*    emailjs.send(service_id, template_id, params)
  	.then(function(){ 
       alert("Sent!");
       myform.find("button").text("Send");
     }, function(err) {
       alert("Send email failed!\r\n Response:\n " + JSON.stringify(err));
       myform.find("button").text("Send");
    }); */
  };

  render() {
    const { loading } = this.state;
    const style = {};

    let button = (
      <Button variant="secondary" type="submit" id="sendBtn">
        Skicka
      </Button>
    );

    if (this.state.loading) {
      button = (
        <div className="d-flex flex-grow-1 justify-content-start">
          <SwapSpinner size={50} color="#6c757d" loading={loading} />
        </div>
      );
    }

    return (
      <Container style={{ marginTop: "2em" }}>
        <Row>
          <Col />
          <Col lg={12}>
            <Form onSubmit={this.handleSubmit} style={{ textAlign: "left" }}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Förnamn</Form.Label>
                <Form.Control
                  name="name"
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.name}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Mailadress</Form.Label>
                <Form.Control
                  name="from"
                  onChange={this.handleChange}
                  type="email"
                  value={this.state.from}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Telefon</Form.Label>
                <Form.Control
                  name="phone"
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.phone}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Meddelande</Form.Label>
                <Form.Control
                  as="textarea"
                  aria-label="With textarea"
                  name="message"
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.message}
                  required
                />
              </Form.Group>
              {button}
              {/* <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" />
              </Form.Group>
              <Form.Group controlId="formBasicChecbox">
                <Form.Check type="checkbox" label="Check me out" />
              </Form.Group> */}
            </Form>
          </Col>
          <Col />
        </Row>
      </Container>
    );
  }
}

export default MailForm;
