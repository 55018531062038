import flamelink from "flamelink";
import * as firebase from "firebase";

const DB_CONFIG = {
  apiKey: "AIzaSyBhnuo4sYNzPU1qzzSfyQPmA_X0bzepFWk",
  authDomain: "pt-marcus.firebaseapp.com",
  databaseURL: "https://pt-marcus.firebaseio.com",
  projectId: "pt-marcus",
  storageBucket: "pt-marcus.appspot.com",
  messagingSenderId: "862799940249"
};

const firebaseApp = firebase.initializeApp(DB_CONFIG);

export default flamelink({ firebaseApp });
