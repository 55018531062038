import React from "react";
import bgPersonligTraning from "../../assets/images/foretagstraning_bg.jpg";
import HeaderImage from "../General/HeadImage";
import Footer from "../Footer/Footer";
import NavRoute from "../NavRoute/NavRoute";
import { Helmet } from "react-helmet";
import ptMarcus from "../../assets/images/bg_pt-marcus_bg2.png";
import Welcome from "../General/Welcome";

import gymbild_1 from "../../assets/images/gymet_1.png";
import gymbild_2 from "../../assets/images/gymet_2.png";
import gymbild_3 from "../../assets/images/gymet_3.png";
import gymbild_4 from "../../assets/images/gymet_4.png";
import gymbild_5 from "../../assets/images/gymet_5.png";
import gymbild_6 from "../../assets/images/gymet_6.png";

const photos = [
  {
    src: gymbild_1,
    width: 4,
    height: 3
  },
  {
    src: gymbild_2,
    width: 5,
    height: 4
  },
  {
    src: gymbild_3,
    width: 2,
    height: 3
  },
  {
    src: gymbild_4,
    width: 4,
    height: 3
  },
  {
    src: gymbild_5,
    width: 3,
    height: 3
  },
  {
    src: gymbild_6,
    width: 4,
    height: 3
  }
];

const style = {
  bottomRow: {
    marginBottom: "3em"
  }
};

const ForetagsTraning = () => {
  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PT Marcus, personlig tränare på östermalm</title>
        <meta
          name="description"
          content="Marcus Englund, en personlig tränare i hjärtat på östermalm. Läs mer om företagsträning här."
        />
        <meta
          property="og:title"
          content="PT Marcus din företagstränare på östermalm, stockholm."
        />
        <meta
          property="og:description"
          content="PT Marcus erbjuder personlig, duo, samt företags träning, i en trygg miljö i centrala östermalm."
        />
        <meta property="og:url" content="https://www.ptmarcus.com" />
        <meta property="og:site_name" content="pt-marcus" />
        <meta property="og:type" content="Website" />
        <meta
          name="keywords"
          content="personlig, pt, stockholm, personlig tränare, östermalm"
        />
        <meta property="og:image" content={ptMarcus} />
      </Helmet>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PT Marcus, personlig tränare på östermalm</title>
        <meta
          name="description"
          content="Marcus Englund, en personlig tränare i hjärtat på östermalm. Se bilder på gymet här."
        />
        <meta
          property="og:title"
          content="PT Marcus din personliga tränare på östermalm, stockholm."
        />
        <meta
          property="og:description"
          content="PT Marcus erbjuder personlig, duo, samt företags träning, i en trygg miljö i centrala östermalm."
        />
        <meta property="og:url" content="https://www.ptmarcus.com" />
        <meta property="og:site_name" content="pt-marcus" />
        <meta property="og:type" content="Website" />
        <meta
          name="keywords"
          content="personlig, pt, stockholm, personlig tränare, östermalm"
        />
        <meta property="og:image" content={ptMarcus} />
      </Helmet>
      <NavRoute />
      <HeaderImage
        bgImage={bgPersonligTraning}
        heading={"Företagsträning"}
        subHeading={"Utvecklas tillsammans med dina kollegor"}
      />
      <Welcome
        heading="Träna hela avdelningen"
        text="Här jobbar vi med grupp-träning som ett verktyg att bygga laganda på din arbetsplats. Beroende på behov så diskuterar vi fram ett upplägg som passar er. 
        Det kan till exempel inkludera:"
        bulletOne="Gruppträning baserad på era mål, kanske ska ni träna för ett gemensamt lopp. "
        bulletTwo="Före och efter mätningar (fys-tester, fettmätning). "
        bulletThree="Kostprogram/kostrådgivning."
        showContactBtn={true}
      />

      <Footer />
    </div>
  );
};

export default ForetagsTraning;
