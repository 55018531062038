import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

const HeadImage = props => {
  const style = {
    img: {
      backgroundImage: `url(${props.bgImage})`,
      backgroundSize: "cover",
      height: "70vh",
      backgroundPosition: "center",
      paddingLeft: "0px",
      paddingRight: "0px"
    },
    centerHeading: {
      height: "100%"
    },
    headingText: {
      color: "white",
      fontSize: "4rem",
      fontWeight: "bold"
    },
    subText: {
      color: "white"
    },
    overlay: {
      height: "100%",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.5)",
      paddingLeft: "-15px",
      paddingRight: "-15px"
    },
    contactBtn: {
      backgroundColor: "transparent",
      color: "white"
    }
  };
  let isOverlap = {
    marginBottom: ""
  };
  if (props.overlap) {
    isOverlap = {
      marginBottom: "-4em"
    };
  }

  let linkBtn = null;
  if (props.btnLink) {
    linkBtn = (
      <Button
        onClick={() => {
          props.history.push("/Kontakt");
        }}
        style={style.contactBtn}
        variant="light"
      >
        Boka en kostnadsfri konsultation
      </Button>
    );
  }
  return (
    <Container fluid style={isOverlap}>
      <Row>
        <Col style={style.img}>
          <div style={style.overlay}>
            <div
              className="d-flex flex-column flex-grow-1 align-items-center justify-content-center"
              style={style.centerHeading}
            >
              <h4 className="headingText" style={style.headingText}>
                {props.heading}
              </h4>
              <p style={style.subText}>{props.subHeading}</p>
              {linkBtn}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(HeadImage);
