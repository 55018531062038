import React from "react";
import bgTraningstips from "../../assets/images/bg_pt-marcus_traningstips.png";
import HeaderImage from "../General/HeadImage";
import Footer from "../Footer/Footer";
import NavRoute from "../NavRoute/NavRoute";
import PostList from "../General/Traningslista/PostList";

const Traningstips = () => {
  return (
    <div className="page">
      <NavRoute />
      <HeaderImage
        bgImage={bgTraningstips}
        heading={"Träningstips"}
        subHeading={"Tips och råd kring träning"}
      />
      <PostList limited={false} />
      <Footer />
    </div>
  );
};

export default Traningstips;
