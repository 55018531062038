import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class Post extends Component {
  /* navigate = (heading, image, skapadAv, fullText, tipKey) => {
    this.props.history.push({
      pathname: `/Tips:${tipKey}`,
      heading: heading,
      image: image,
      skapadAv: skapadAv,
      fullText: fullText,
      tipKey: tipKey
    });
  }; */
  navigate = (heading, image, skapadAv, fullText, tipKey) => {
    this.props.history.push(`/Tips:${tipKey}`);
  };
  render() {
    const { heading, text, image, skapadAv, fullText, tipKey } = this.props;
    const style = {
      container: {
        marginBottom: "2em",
        width: "18rem",
        cursor: "pointer"
      },
      image: {
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        height: "15rem",
        backgroundPosition: "center"
      }
    };

    return (
      <Card
        style={style.container}
        onClick={() => {
          this.navigate(heading, image, skapadAv, fullText, tipKey);
        }}
      >
        <div variant="top" src={image} style={style.image} />
        <Card.Body>
          <Card.Title>{heading}</Card.Title>
          <Card.Text>{text}</Card.Text>
        </Card.Body>
      </Card>
    );
  }
}
export default withRouter(Post);
