import React, { Component } from "react";
import HeaderImage from "../General/HeadImage";
import Offerings from "./Offerings";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import kostImage from "../../assets/images/kost.png";
import personligtImage from "../../assets/images/personlig_traning_2_kvalite.jpg";
import foretagstraningImage from "../../assets/images/foretagstraning.png";
import Welcome from "../General/Welcome";
import mainBg from "../../assets/images/bg_pt-marcus_start.png";
import Footer from "../Footer/Footer";
import NavRoute from "../NavRoute/NavRoute";
import { Helmet } from "react-helmet";
import ptMarcus from "../../assets/images/bg_pt-marcus_bg2.png";

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>PT Marcus, personlig tränare på östermalm</title>
          <meta
            name="description"
            content="Marcus Englund, en personlig tränare i hjärtat på östermalm. "
          />
          <meta
            property="og:title"
            content="PT Marcus din personliga tränare på östermalm, stockholm."
          />
          <meta
            property="og:description"
            content="PT Marcus erbjuder personlig, duo, samt företags träning, i en trygg miljö i centrala östermalm."
          />
          <meta property="og:url" content="https://www.ptmarcus.com" />
          <meta property="og:site_name" content="pt-marcus" />
          <meta property="og:type" content="Website" />
          <meta
            name="keywords"
            content="personlig, pt, stockholm, personlig tränare, östermalm"
          />
          <meta property="og:image" content={ptMarcus} />
        </Helmet>
        <NavRoute />
        <HeaderImage
          bgImage={mainBg}
          heading={"PT Marcus"}
          subHeading={"”Träning ska vara en naturlig del av livet”"}
          btnLink={true}
          overlap={true}
        />
        <Container>
          <Row className="d-flex flex-grow-1 justify-content-center">
            <Offerings
              heading={"Personlig träning"}
              image={personligtImage}
              linkTo={"/PersonligTraning"}
            />
            <Offerings
              heading={"Företagsträning"}
              image={foretagstraningImage}
              linkTo={"/ForetagsTraning"}
            />
            <Offerings
              heading={"Kostrådgivning"}
              image={kostImage}
              linkTo={"/KostRadgivning"}
            />
          </Row>
        </Container>
        <Welcome
          heading="Detta är PT Marcus"
          text="Jag har erfarenhet av att träna kunder i olika stadier i livet och med olika mål och förutsättningar då min yngsta kund är 13 år och min äldsta är 76 år. Jag tränar kunder individuellt, med en kompis, eller i större grupper (t.ex. i företag eller lag) men har alltid individen i fokus så att alla i gruppen känner att de kan delta på samma villkor. 
          Tillsammans tar vi fram ett program utformat efter dina intressen och mål. Jag tror inte att det finns en mall som passar alla utan alla mina kunder får ett unikt program.
          Min största prioritering är att vi tillsammans ska vi bygga en hälsosam och hållbar livsstil med både träning och kost genom att hitta långsiktiga lösningar till just dina utmaningar. 
          Utbildning: Kandidatexamen i idrottsvetenskap, Northridge University, Los Angeles.
          "
          /* text="Bakgrund: Marcus intresse för idrott och hälsa kom
            naturligt för honom från hans uppväxt där han var aktiv inom
            handboll, kampsport och alpint. Intresset ledde honom vidare till en
            utbildning i USA där han tog en kandidatexamen inom träningslära.
            Marcus älskar utmaningen av att möta klienter med olika bakgrunder,
            inte minst när det kommer till fysik men även deras livsstil och
            hitta lösningar som passar just dem för att skapa en långvarig
            livsstilsförändring." */
        />

        <Footer />
      </div>
    );
  }
}

export default Home;
