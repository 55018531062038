import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/ptm_logo.png";

const NavRoute = () => {
  /* window.onscroll = () => {
    const nav = document.querySelector("#navbar");
    if (this.scrollY <= 10) nav.className = "navbar-default";
    else nav.className = "navbar-scroll";
  }; */
  const style = {
    fontColor: {
      color: "white",
      fontSize: "bold"
    },
    logo: {
      height: "3em"
    }
  };
  return (
    <Navbar
      bg="black"
      variant="dark"
      expand="lg"
      id="navbar"
      style={{
        position: "fixed",
        width: "100%",
        zIndex: "999"
      }}
    >
      <Navbar.Brand as={Link} to="/" href="#home" style={style.fontColor}>
        <img src={Logo} alt="PT-marcus logotype" style={style.logo} />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" style={style.fontColor} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="d-flex flex-grow-1 manuBgColor">
          <Container fluid>
            <Row>
              <Col
                md={12}
                className="d-flex flex-grow-1 justify-content-end responsiveCustomMenu"
              >
                <Nav.Link
                  as={Link}
                  to="/"
                  className="p-4"
                  href="#PTMarcus"
                  style={style.fontColor}
                >
                  Välkommen till PT Marcus
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/Gymet"
                  className="p-4"
                  href="#Gymet"
                  style={style.fontColor}
                >
                  Gymet
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/Traningstips"
                  className="p-4"
                  href="#Träningstips"
                  style={style.fontColor}
                >
                  Träningstips
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/Kontakt"
                  className="p-4"
                  href="#Kontakt"
                  style={style.fontColor}
                >
                  Kontakt
                </Nav.Link>
              </Col>
            </Row>
          </Container>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavRoute;
