import React, { Component } from "react";
import Footer from "../Footer/Footer";
import NavRoute from "../NavRoute/NavRoute";
import HeaderImage from "../General/HeadImage";
import PostList from "../General/Traningslista/PostList";
import { Container, Row, Col } from "react-bootstrap";
import app from "../../Config/Config";
import { SwapSpinner } from "react-spinners-kit";
import { Helmet } from "react-helmet";
import ptMarcus from "../../assets/images/bg_pt-marcus_bg2.png";

export default class Tips extends Component {
  _isMounted = false;
  //Constructor of the class, will be called once
  constructor() {
    super();

    this.app = app;
    this.data = "";
  }
  //The components State
  state = {
    posts: {},
    loading: true
  };
  //ComponentDidMount will be called after the first Render() method is complete.
  componentDidMount() {
    this._isMounted = true;
    const { myKey } = this.props.match.params;

    this.timeOutHandler = setTimeout(() => {
      this.app.content
        .get("traningstips", myKey.substring(1))
        .then(tipsData => {
          this.app.storage
            .getURL(tipsData.mainImage[0])
            .then(url => {
              console.dir("inne i app.storage: " + tipsData);
              this.setState({
                posts: {
                  postKey: tipsData.id,
                  rubrik: tipsData.rubrik,
                  innehall: tipsData.innehall,
                  mainImage: url,
                  skapadAv: tipsData.skapadAv
                },
                loading: false
              });
            })
            .catch(error =>
              console.error(
                "Something went wrong while retrieving the file URL. Details:",
                error
              )
            );
        })
        .catch(error =>
          console.error(
            "Something went wrong while retrieving all the content. Details:",
            error
          )
        );
    }, 50);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.app.content.unsubscribe("traningstips");
    this.app.storage.unsubscribe("*");
    if (this.timeOutHandler) {
      clearTimeout(this.timeOutHandler);
      this.timeOutHandler = 0;
    }
  }

  render() {
    const { loading } = this.state;
    let postData = "";
    console.log("postKey: " + this.state.posts.postKey);
    if (this.state.posts.postKey) {
      postData = {
        image: this.state.posts.mainImage,
        heading: this.state.posts.rubrik,
        fullText: this.state.posts.innehall
      };
    }
    console.log(postData);
    const style = {
      blogContainer: {
        paddingTop: "4em",
        textAlign: "left",
        lineHeight: "1.8em"
      },
      outerContainer: {
        borderTop: "1px",
        borderTopStyle: "solid",
        borderTopColor: "black",
        borderRadius: "10px"
      },
      marginTop: {
        marginTop: "4em"
      },
      loadingContainer: {
        height: "100vh"
      },
      logo: {
        height: "3em"
      }
    };

    let content = (
      <Container>
        <Row style={style.loadingContainer}>
          <Col className="d-flex flex-grow-1 justify-content-center align-items-center flex-column">
            <SwapSpinner size={50} color="#6c757d" loading={loading} />
            {/*  <img src={Logo} alt="PT-marcus logotype" style={style.logo} /> */}
          </Col>
        </Row>
      </Container>
    );
    if (!loading) {
      content = (
        <>
          <NavRoute />
          <HeaderImage bgImage={postData.image} heading={postData.heading} />
          <Container>
            <Row>
              <Col style={style.blogContainer}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: postData.fullText
                  }}
                  id="tipsContainer"
                />
                <h4 style={style.marginTop}>Fler träningstips</h4>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col style={style.outerContainer}>
                <PostList limited={true} />
              </Col>
            </Row>
          </Container>

          <Footer />
        </>
      );
    }

    return (
      <div className="page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>PT Marcus, personlig tränare på östermalm. Träningstips</title>
          <meta
            name="description"
            content="Marcus Englund, en personlig tränare i hjärtat på östermalm. Här kommer lite generella träningstips!"
          />
          <meta
            property="og:title"
            content="PT Marcus din personliga tränare på östermalm, stockholm."
          />
          <meta
            property="og:description"
            content="PT Marcus erbjuder personlig, duo, samt företags träning, i en trygg miljö i centrala östermalm."
          />
          <meta property="og:url" content="https://www.ptmarcus.com" />
          <meta property="og:site_name" content="pt-marcus" />
          <meta property="og:type" content="Website" />
          <meta
            name="keywords"
            content="personlig, pt, stockholm, personlig tränare, östermalm"
          />
          <meta property="og:image" content={ptMarcus} />
        </Helmet>
        {content}
      </div>
    );
  }
}
